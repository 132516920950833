import cloneDeep from "lodash/cloneDeep";
import { DateTime } from "luxon";

export const helpers = {
  data: () => ({
    loading: false,
    scanRepositoryRunning: false,
    calculationClusterRunning: false,
    calculationGrammarRunning: false,
    calculationClassificationsRunning: false,
    sambaConnectorRunning: false,
    databaseConnectorRunning: false,
    exchangeConnectorRunning: false,
    nextcloudConnectorRunning: false,
    scanRunning: false,
    calculationRunning: false,
    statusMessage: "",
    connectorTypes: {
      sambaConnectorRunning: "• Samba",
      nextcloudConnectorRunning: "• NextCloud",
      databaseConnectorRunning: "• Database",
      exchangeConnectorRunning: "• Exchange",
    },
  }),

  methods: {
    getRunningScanDetails(connectorType) {
      let apiurl = "getCacheControllerById/";
      this.axios
        .post(apiurl, { key: connectorType })
        .then(({ data }) => {
          if (Object.keys(this.connectorTypes).includes(connectorType)) {
            this[connectorType] = data.status;
            let message = data.status
              ? this.$t("connectorScanning", { connector: this.connectorTypes[connectorType]})
              : "";
            this.statusMessage = this.statusMessage + message;
          }
        })
        .catch((e) => {
          this.handleError(e);
        });
    },
    getScanProgress() {
      let apiurl = "getCacheControllerById/";
      this.axios
        .post(apiurl, { key: "scan_running" })
        .then(({ data }) => {
          if (data.scan_running) {
            this.scanRepositoryRunning = true;
            let message = this.$t("waitForScanToFinish");
            this.getRunningScanDetails("sambaConnectorRunning");
            this.getRunningScanDetails("nextcloudConnectorRunning");
            this.getRunningScanDetails("databaseConnectorRunning");
            this.getRunningScanDetails("exchangeConnectorRunning");
            this.statusMessage = this.statusMessage + message;
            this.showMessage(this.statusMessage, 4);
            this.statusMessage = "";
            setTimeout(this.getScanProgress, 5000);
          } else {
            this.scanRepositoryRunning = false;
          }
        })
        .catch((e) => {
          this.handleError(e);
        });
    },
    getCalculationClusterProgress() {
      let apiurl = "getCacheControllerById/";
      this.axios
        .post(apiurl, { key: "nlp_calculate_clusters_started" })
        .then(({ data }) => {
          if (data.nlp_calculate_clusters_started) {
            // this.showMessage(
            //   "Calculation cluster in progress. Please wait for the calculation to finish first.",
            //   4
            // );
            this.calculationClusterRunning = true;
            setTimeout(this.getCalculationClusterProgress, 1000);
          } else {
            this.calculationClusterRunning = false;
          }
        })
        .catch((e) => {
          this.handleError(e);
        });
    },
    getCalculationGrammarProgress() {
      let apiurl = "getCacheControllerById/";
      this.axios
        .post(apiurl, { key: "nlp_calculate_grammars_started" })
        .then(({ data }) => {
          if (data.nlp_calculate_grammars_started) {
            // this.showMessage(
            //   "Calculation grammars in progress. Please wait for the calculation to finish first.",
            //   4
            // );
            this.calculationGrammarRunning = true;
            setTimeout(this.getCalculationGrammarProgress, 1000);
          } else {
            this.calculationGrammarRunning = false;
          }
        })
        .catch((e) => {
          this.handleError(e);
        });
    },
    getCalculationClassificationProgress() {
      let apiurl = "getCacheControllerById/";
      this.axios
        .post(apiurl, { key: "nlp_calculate_classifications_started" })
        .then(({ data }) => {
          if (data.nlp_calculate_classifications_started) {
            // this.showMessage(
            //   "Calculation classifications in progress. Please wait for the calculation to finish first.",
            //   4
            // );
            this.calculationClassificationsRunning = true;
            setTimeout(this.getCalculationClassificationProgress, 1000);
          } else {
            this.calculationClassificationsRunning = false;
          }
        })
        .catch((e) => {
          this.handleError(e);
        });
    },
    formatDateTime(dateTime, format) {
      return this.$luxon
        .fromISO(dateTime)
        .toFormat(format ? format : "dd/LL/yyyy HH:mm");
    },
    createdDate(date) {
      const d = this.$luxon.fromISO(date);
      if (DateTime.now().hasSame(d, "year")) {
        if (DateTime.now().hasSame(d, "day")) {
          return d.toFormat("HH:mm");
        } else {
          return d.toFormat("d LLL");
        }
      } else return d.toFormat("d LLL yyyy");
    },
    formatSize(Num = 0, dec = 2) {
      if (Num === null) {
        return;
      }
      if (Num < 1000) return Num + " Bytes";
      Num = ("0".repeat(((Num += "").length * 2) % 3) + Num).match(/.{3}/g);
      return (
        Number(Num[0]) +
        "." +
        Num[1].substring(0, dec) +
        " " +
        "  kMGTPEZY"[Num.length] +
        "B"
      );
    },
    shorten(str, count) {
      if (str.length > count + 3) return str.substr(0, count) + "...";
      else return str;
    },
    showMessage(msg, type) {
      if (type !== undefined) {
        this.$toast.setMessage(msg, type);
      } else {
        this.$toast.setMessage(msg, 2);
      }
    },
    handleError(error) {
      let status = null;
      this.loading = false;
      let msg;
      let r;

      if (error.data) {
        r = error.data;
      } else if (error.response) {
        status = error.response.status;

        //unauthorized ya da forbidden dönerse logout yap
        // if ([401, 403].includes(status)) {
        //   this.$store.commit("auth/purgeAuth", status);
        //
        //   return;
        // }
        if (error.response.data) {
          r = error.response.data;
        }
      } else {
        r = error;
      }

      if (r.hasOwnProperty("non_field_errors")) {
        msg = r.non_field_errors[0];
      } else if (r.hasOwnProperty("detail")) {
        msg = r.detail;
      } else {
        if (typeof r === "object") {
          const item = Object.entries(r)[0];
         if (Array.isArray(item[1])) {
            msg = item[1][0];
          } else {
            msg = item[1];
          }
        } else {
          msg = r;
        }
      }

      this.showMessage(msg, 1);
    },
    deepCopy(obj) {
      return cloneDeep(obj);
    },
    defined(obj) {
      return !(obj === undefined || obj === null);
    },
    GDPRsetItem(source, target) {
      let status = {};
      Object.keys(target).forEach((key) => {
        if (source[key]) {
          if (source[key].hasOwnProperty("id")) {
            status[key] = source[key].id;
          } else if (Array.isArray(source[key])) {
            status[key] = source[key].map((s) => s.id);
          } else {
            status[key] = source[key];
          }
        } else {
          status[key] = null;
        }
      });
      return status;
    },
  },
};

import { getToken, getRefresh, saveToken } from "@/services/jwt.service";
import axios from "axios";
import router from "@/router";

let baseUrl = "/api/";
const $axios = axios.create({
  baseURL: baseUrl,
});

// İstek interceptor'u
$axios.interceptors.request.use(
  (config) => {
    let lang = localStorage.getItem("resepsiyon-language");
    if (!lang) {
      localStorage.setItem("resepsiyon-language", "tr");
      lang = localStorage.getItem("resepsiyon-language");
    }
    config.headers["Accept-Language"] = lang;
    
    const token = getToken();
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Yanıt interceptor'u
$axios.interceptors.response.use((response) => {
  return response;
}, async (error) => {
  const originalRequest = error.config;
  if (error.response.status === 401 && originalRequest.url === "/token/refresh/")
    {
      console.log('first if');
      // const path = `/login`
      // if (this.$route.path !== path) this.$router.push(path)
      router.push("/login");
      return Promise.reject(error);
    }
  if ([403,401].includes(error.response.status) && !originalRequest._retry) {
    console.log('second if');
    originalRequest._retry = true;
    const refreshToken = getRefresh();
    if (refreshToken) {
      try {
        console.log('second if try');
        const response = await $axios.post('/token/refresh/', { refresh: refreshToken });
        saveToken(response.data.access)
        $axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.access}`;
        return $axios(originalRequest);
      } catch (refreshError) {
        console.log('second if catch');
        console.log('Refresh token invalid, logging out user.');
        localStorage.removeItem('access');
        localStorage.removeItem('refresh');
        // const path = `/login`
        // if (this.$route.path !== path) this.$router.push(path)
        router.push("/login");
      }
    }
  }
  return Promise.reject(error);
});

export default $axios;

import i18n from "@/plugins/i18n";
import { ValidationObserver, ValidationProvider, extend, localize } from "vee-validate";
import en from 'vee-validate/dist/locale/en.json';
import tr from 'vee-validate/dist/locale/tr.json';
import {
  alpha_num,
  between,
  confirmed,
  email,
  integer,
  length,
  max,
  max_value,
  min,
  min_value,
  numeric,
  regex,
  required,
} from "vee-validate/dist/rules";
import Vue from "vue";

localize({
  en,
  tr
});

import { DateTime } from "luxon";

extend("required", {
  ...required,
  message: i18n.t("validations.required"),
});

extend("min", {
  ...min,
  message: (_, values) => i18n.t("validations.min", values),
});

extend("min_value", {
  ...min_value,
  message: (_, values) => i18n.t("validations.min_value", values),
});

extend("max", {
  ...max,
  message: (_, values) => i18n.t("validations.max", values),
});

extend("max_value", {
  ...max_value,
  message: (_, values) => i18n.t("validations.max_value", values),
});

extend("between", {
  ...between,
  message: (_, values) => i18n.t("validations.between", values),
});

extend("email", {
  ...email,
  message: i18n.t("validations.email"),
});
extend("numeric", {
  ...numeric,
  message: i18n.t("validations.numeric"),
});
extend("integer", {
  ...integer,
  message: i18n.t("validations.integer"),
});
extend("alpha_num", {
  ...alpha_num,
  message: i18n.t("validations.alpha_num"),
});
extend("length", length);

extend("regex", regex);

extend("confirmed", {
  ...confirmed,
  message: i18n.t("validations.confirmed"),
});

extend("sameDomain", {
  params: ["target"],
  validate(value, { target }) {
    let domain = value.split("@")[1];
    if (Array.isArray(target)) {
      return !!target.find((v) => v === domain);
    } else return domain === target;
  },
  message: i18n.t("validations.sameDomain"),
});

extend("time", {
  validate(value) {
    return DateTime.fromFormat(value, "HH:mm").isValid;
  },
  message: i18n.t("validations.time"),
});

Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);

import Vue from "vue";
import Vuex from "vuex";

import auth from "./auth.module";
import parameters from "./parameters.module";
import permissions from "./permissions.module";
import layout from "./layout.module";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    menu: false,
    isPageLoading: false,
    message: {
      content: "",
      type: null,
    },
    pageCounter: 0,
    scanStatus: {
      percentage: 0,
      repository_name: "",
      file_name: "",
    },
    privacy_levels: localStorage.getItem('privacy_levels') ? JSON.parse(localStorage.getItem('privacy_levels')): []
  },
  getters: {
    getScanStatus(state) {
      return state.scanStatus;
    },
  },
  mutations: {
    toggleMenu(state) {
      state.menu = !state.menu;
    },
    setMessage(state, { content, type }) {
      state.message.content = content;
      state.message.type = type;
    },
    setPageCounter(state) {
      state.pageCounter++;
    },
    setScanStatus(state, payload) {
      state.scanStatus = payload;
    },
    setPageLoading(state, payload) {
      if (!payload) {
        setTimeout(() => {
          state.isPageLoading = payload;
        }, 700);
      } else {
        state.isPageLoading = payload;
      }
    },
    setPrivacyLevels(state, data) {
      state.privacy_levels = data;
      localStorage.setItem('privacy_levels', JSON.stringify(data))
    },
  },
  actions: {},
  modules: {
    auth,
    layout,
    permissions,
    parameters,
  },
});

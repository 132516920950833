import CommonTranslations from "@/translations/Common";
import Vue from "vue";
import VueI18n from "vue-i18n";
Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: "tr",
  fallbackLocale: "tr",
  messages: CommonTranslations,
  silentFallbackWarn: true,
});

export const availableLanguages = [
  { code: "tr", name: "Türkçe", isoCode: "tr-tr" },
  { code: "en", name: "English", isoCode: "en-us" },
];

export default i18n;

import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import en from "vuetify/lib/locale/en";
import tr from "vuetify/lib/locale/tr";

Vue.use(Vuetify);


export default new Vuetify({
  lang: {
    locales: { en, tr },
    current: "tr",
  },
  theme: {
    // dark: true,
  },
});

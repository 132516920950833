import router from "@/router";
import JwtService from "@/services/jwt.service";
import flatten from "lodash/flatten";

const state = {
  token: null,
  isAuthenticated: localStorage.getItem("isAuthenticated") ? localStorage.getItem("isAuthenticated") : null,
  user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {},
  permissions: localStorage.getItem("user_permissions") ? JSON.parse(localStorage.getItem("user_permissions")) : {},
};

const getters = {
  permissions(state) {
    if (state.user.is_superuser || !state.user.organization) {
      return [];
    }
    return [
      ...new Set(
        flatten(state.user.organization.roles.map((v) => v.permissions.map((z) => z.slug)))
      ),
    ];
  },
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
};

const mutations = {
  setAuth(state, payload) {
    state.isAuthenticated = true;
    state.user = { ...payload };
    state.permissions = state.user.is_superuser || !state.user.organization ? [] : [
      ...new Set(
        flatten(state.user.organization.roles.map((v) => v.permissions.map((z) => z.slug)))
      ),
    ]
    localStorage.setItem("isAuthenticated", true);
    localStorage.setItem("user", JSON.stringify({ ...payload }));
    localStorage.setItem("user_permissions", JSON.stringify(state.permissions));
  },
  purgeAuth(state, status = 0) {
    if (router.history.current.name !== "login") {
      router.push({ name: "login", params: { status: status } }).then(() => {
        state.isAuthenticated = false;
        state.user = {};
        JwtService.destroyToken();
      });
    }
  },
};

const actions = {
  async logOut(context) {
    await router.push("/login").then(() => {
      context.commit("purgeAuth");
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};

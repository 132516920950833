import { mapGetters } from "vuex";

export const permissionMixin = {
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      userPermissions: "auth/permissions"
      // userPermissions: "layout/getFlattedList"
    }),
  },
  methods: {
    hasUserModulePermissions(url) {
      if (this.currentUser.is_superuser) {
        return true;
      }
      const permission = this.userPermissions.find((permission) => permission.url === url.path);
      if (!permission) {
        return false;
      };
      return permission.write.some(p => p.slug === 'write' + url.meta.check);
    },
    hasPermission(perm) {
      return this.currentUser.is_superuser || (this.userPermissions.includes(perm))
    }
  },
};

import { getToken } from "@/services/jwt.service";
import { storeBridge } from "@/services/socket.service";
import ReconnectingWebSocket from "reconnecting-websocket";

let ws_scheme = window.location.protocol === "https:" ? "wss" : "ws";
let path = window.location.host;
let try_count = 0

let room_code = null;

function set_room_code(new_code) {
  room_code = new_code;
}
let urlProvider = async () => {
  const token = await getToken();
  const language = localStorage.getItem("resepsiyon-language");
  // return ws_scheme + "://" + path + "/ws/notifications" + "/?token=" + token;
  return ws_scheme + "://" + path + "/ws/notifications";
};

const socket = new ReconnectingWebSocket(urlProvider, [], {
  startClosed: true,
  minReconnectionDelay: 1000 + Math.random() * 500,
  maxRetries: 5,
});


socket.addEventListener("open", () => {
  // store.commit("socket/SET_CONNECTION_STATUS", true);
  console.warn("Websocket Open");
});
socket.addEventListener("message", (message) => {
  storeBridge(JSON.parse(message.data));
  let details = JSON.parse(message.data).details || {};
  if (details.hasOwnProperty("error")) {
    // TODO: show user an error if websocket returns error as a progress
    console.error(details.error);
  }
});
socket.addEventListener("close", (message) => {
  //store.commit("socket/SET_CONNECTION_STATUS", false);
  console.warn("Websocker Closed With: ", message);
  try_count++;
  if(try_count < 6) {
    console.log('yeniden bağlanıyor....')
    socket.reconnect();
  } else {
    console.log('maximum deneme sayısına ulaşıldı', try_count)
  }
});
socket.addEventListener("error", (error) => {
  console.error(error);
});

export { socket, set_room_code };

const CommonTranslations = {
  en: {
    validations: {
      maxNCharacter: "Max {value} characters",
      required: "This field is required",
      min: "This field must be {length} characters or more",
      min_value: "This value can't be lower than {min}",
      max: "This field must be {length} characters or less",
      max_value:
        "This value can't be bigger than {max}. You can change this amount on Settings Page ",
      between: "This field must be between {min} and {max}",
      email: "This field must be a valid email",
      numeric: "The field may only contain numeric characters",
      integer: "The field may only contain integers",
      alpha_num: "The field may only contain numbers and letters",
      confirmed: "Passwords do not match",
      sameDomain: "Users email addresses must be from contractor domain",
      time: "Please enter a valid time",
    },
    periods: {
      daily: "Daily",
      weekly: "Weekly",
      monthly: "Monthly",
    },
    actions: {
      search: "Search",
      edit: "Edit",
      delete: "Delete",
      cancel: "Cancel",
      close: "Close",
      save: "Save",
      confirm: "OK",
      unblock: "Unblock",
      saveChanges: "@:(actions.save) Changes",
    },
    deleteStatus: {
      successful: "Deleted Successfully",
    },
    saveStatus: {
      successful: "Saved Successfully",
      failed: "Save Failed",
    },
    commonStatuses: {
      active: "Active",
      deactive: "Deactive",
      activated: "Activated",
      deactivated: "Deactivated",
      enabled: "Enabled",
      enable: "Enable",
      disabled: "Disabled",
      disable: "Disable",
    },
    common: {
      new: "New",
      email: "Email",
      address: "Address",
      password: "Password",
      rePassword: "Re-Password",
      name: "Name",
      firstName: "First @:(common.name)",
      lastName: "Last @:(common.name)",
      userName: "User @:(common.name)",
      created: "Created",
      data_not_found: "No Recorded Data Found",
    },
    deleteConfirm: `Are you sure you want to <b>delete</b> {name}`,
    waitForScanToFinish: "Please wait for the scanning to finish first.",
    pages: {
      login: "Login",
      register: "Register",
      forgotPassword: "Forgot Password",
      resetPassword: "Reset Password",
      setPassword: "Set Password",
      dashboard: "Dashboard",
      scanRepository: "Scan Repository",
      SyncRepository: "Sync Repository",
      RunGrammar: "Run Grammar",
      users: "Users",
      userDatabaseConnection: "User Database Connection",
      failedLoginThresholds: "Failed Login Thresholds",
      blockedUsers: "Blocked Users",
      repositories: "Repositories",
      discovery: "Discovery",
      clustering: "Data Clustering",
      classification: "Classification",
      reports: "Reports",
      riskManagement: "Risk Management",
      monitoringAndControl: "Monitoring And Control",
      requestLog: 'Request Logs',
      databaseLog: 'Database Logs',
      riskManagementSettings: "Risk-Management Settings",
      riskManagementPlan: "Risk Management Plan",
      settings: "Settings",
      generalSettings: "General Settings",
      dataPermissions: "Data Permissions",
      organizations: "Organizations",
      parameters: "Parameters",
      grammars: "Grammars",
      grammars_data_types: "Grammar Rules Types",
      grammars_data_categories: "Grammar Data Categories",
      grammars_tokens: "Grammar Tokens",
      addGrammar: "AddGrammar",
      privacyLevels: "Privacy Levels",
      rolesAndPermissions: "Roles & Permissions",
      databaseMapping: 'Database Mapping',
      classes: 'Classes',
      dataAnalysis: 'Data Analysis',
      physicalLogicalDeletingAnonymizing: 'Anonymizing & Physical Logical Deleting',
      databaseAnonymizing: 'Data Anonymizing',
      physicalLogicalDeleting: 'Physical Logical Deleting',
      userBehaviourAnalysis: 'User Behaviour Analytics',
      userbehaviourusers: 'Users'
    },
  },
  tr: {
    validations: {
      maxNCharacter: "En fazla {value} karakter",
      required: "Bu alan zorunludur",
      min: "Bu alan en az {length} karakter olmalıdır",
      min_value: "Bu değer {min} değerinden küçük olamaz",
      max: "Bu alan en fazla {length} karakter olmalıdır",
      max_value:
        "This value can't be bigger than {max}. You can change this amount on Settings Page ",
      between: "Bu alan {min} ve {max} arasında olmalıdır",
      email: "Bu alan geçerli bir e-posta olmalıdır",
      numeric: "Bu alan yalnızca sayısal karakterler içerebilir",
      integer: "Bu alan yalnızca tamsayılar içerebilir",
      alpha_num: "Bu alan yalnızca sayı ve harf içerebilir",
      confirmed: "Şifreler eşleşmiyor",
      sameDomain: "Kullanıcıların e-posta adresleri yüklenici alanından olmalıdır",
      time: "Lütfen geçerli bir zaman girin",
    },
    periods: {
      daily: "Günlük",
      weekly: "Haftalık",
      monthly: "Aylık",
    },
    actions: {
      search: "Ara",
      edit: "Düzenle",
      delete: "Sil",
      cancel: "İptal",
      close: "Kapat",
      save: "Kaydet",
      confirm: "Tamam",
      unblock: "Engeli Kaldır",
      saveChanges: "Değişiklikleri @:(actions.save)",
    },
    deleteStatus: {
      successful: "Silme işlemi başarılı",
    },
    saveStatus: {
      successful: "Kaydetme işlemi başarılı",
      failed: "Kaydetme işlemi başarısız",
    },
    commonStatuses: {
      active: "Aktif",
      deactive: "Pasif",
      activated: "Aktifleştirildi",
      deactivated: "Pasifleştirildi",
      enabled: "Etkin",
      enable: "Etkinleştir",
      disabled: "Etkisiz",
      disable: "Etkisizleştir",
    },
    common: {
      new: "Yeni",
      email: "E-posta",
      address: "Adres",
      password: "Şifre",
      rePassword: "Şifre Tekrar",
      name: "İsim",
      firstName: "İsim",
      lastName: "Soyisim",
      userName: "Kullanıcı Adı",
      created: "Oluşturulma",
      data_not_found: "Kayıtlı Veri Bulunamadı",
    },
    deleteConfirm: `<b>{name}</b> verisini silmek istediğinize emin misiniz?`,
    waitForScanToFinish: "Lütfen taramanın bitmesini bekleyin.",
    connectorScanning: "{connector} repository is currently being scanned.<br>",
    pages: {
      login: "Giriş",
      register: "Kayıt Ol",
      forgotPassword: "Şifremi Unuttum",
      resetPassword: "Şifre Sıfırla",
      setPassword: "Şifre Belirle",
      dashboard: "Anasayfa",
      scanRepository: "Depo Tara",
      SyncRepository: "Depo Senkronizasyonu",
      RunGrammar: "Dil Kuralı Çalıştırma",
      users: "Kullanıcılar",
      userDatabaseConnection: "Kullanıcı Veritabanı Bağlantısı",
      failedLoginThresholds: "Başarısız Giriş Eşikleri",
      blockedUsers: "Engellenen Kullanıcılar",
      repositories: "Depolar",
      discovery: "Keşif",
      clustering: "Veri Kümeleme",
      classification: "Sınıflandırma",
      reports: "Raporlar",
      riskManagement: "Risk Yönetimi",
      monitoringAndControl: "İzleme ve Denetim",
      requestLog: 'İstek Logları',
      databaseLog: 'Veritabanı Logları',
      riskManagementSettings: "Risk Yönetimi Ayarları",
      riskManagementPlan: "Risk Yönetim Planı",
      settings: "Ayarlar",
      generalSettings: "Genel Ayarlar",
      dataPermissions: "Veri İzinleri",
      organizations: "Organizasyonlar",
      parameters: "Parametreler",
      grammars: "Dil Kuralları",
      grammars_data_types: "Dil Kuralları Türleri",
      grammars_data_categories: "Dil Kuralları Katagorileri",
      grammars_tokens: "Grammar Bileşenleri",
      privacyLevels: "Gizlilik Seviyeleri",
      rolesAndPermissions: "Roller & İzinler",
      databaseMapping: 'Veritabanı Haritalama',
      classes: 'Sınıflar',
      dataAnalysis: 'Veri Analizi',
      physicalLogicalDeletingAnonymizing: 'Anonimleştirme & Fiziksel Mantıksal Silme',
      databaseAnonymizing: 'Veri Anonimleştirme',
      userBehaviourAnalysis: 'Kullanıcı Davranış Analitiği',
      userbehaviourusers: 'Kullanıcılar',
      physicalLogicalDeleting: 'Fiziksel & Mantıksal Silme'
    },
  },
};

export default CommonTranslations;

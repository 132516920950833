import axios from "@/plugins/axios";
import store from "@/store/auth.module"


const state = {
    menuList: [
        {
            title: "dashboard",
            icon: "mdi-home-analytics",
            url: "/dashboard",
            write: []
        },
        {
            title: "repositoryProcesses",
            icon: "mdi-source-repository-multiple",
            permissions: ["Repository", "ScanRepository"],
            group: "repositories",
            roles: [1],
            write: [],
            subItems: [
                {
                    title: "repositories",
                    icon: "mdi-source-repository-multiple",
                    url: "/repositories/repositories",
                    permissions: ["Repository"],
                    write: [],
                },
                {
                    title: "scanRepository",
                    icon: "mdi-database-sync",
                    url: "/repositories/scan",
                    permissions: ["ScanRepository"], // Segments yazmışlar önceden
                    write: []
                },
            ]
        },
        {
            title: "dataAnalysis",
            icon: "mdi-poll",
            roles: [1],
            group: "data-analysis",
            permissions: ["Discovery", "Clustering", "Classification"],
            write: [],
            subItems: [
                {
                    title: "dataDiscovery",
                    icon: "mdi-folder-search-outline",
                    url: "/data-analysis/discovery",
                    permissions: ["Discovery"],
                    write: []
                },
                {
                    title: "clustering",
                    icon: "mdi-chart-bubble",
                    url: "/data-analysis/clustering",
                    permissions: ["Clustering"],
                    write: []
                },
                {
                    title: "classification",
                    icon: "mdi-merge",
                    url: "/data-analysis/classification",
                    permissions: ["Classification"],
                    write: []
                },
                {
                    title: "databaseMapping",
                    icon: "mdi-database-search-outline",
                    url: "/data-analysis/database-mapping",
                    permissions: ["DbMapping"], //Repositories verisi çekiliyor ve backendde readProcessedData kullanılmış ama gerek yok
                    write: [],
                },
            ]
        },

        {
            title: "physicalLogicalDeletingAnonymizing",
            icon: "mdi-incognito",
            roles: [1],
            group: "physical-logical-deleting-anonymizing",
            permissions: ["DbAnonymizing", "PhysicalLogicalDeleting"],
            write: [],
            subItems: [
                {
                    title: "databaseAnonymizing",
                    icon: "mdi-incognito",
                    url: "/physical-logical-deleting-anonymizing/database-anonymizing",
                    permissions: ["DbAnonymizing"],
                    write: []
                },
                {
                    title: "physicalLogicalDeleting",
                    icon: "mdi-delete-variant",
                    url: "/physical-logical-deleting-anonymizing/physical-logical-deleting",
                    permissions: ["PhysicalLogicalDeleting"],
                    write: []
                }
            ]
        },

        // {
        //     title: "reports",
        //     icon: "mdi-printer-outline",
        //     url: "/reports",
        //     permissions: ["Reports"],
        //     write: []
        // },
        {
            title: "riskManagement",
            icon: "mdi-circle-edit-outline",
            roles: [1],
            group: "risk-management",
            permissions: ["RiskManagementSettings", "RiskManagementPlan"],
            write: [],
            subItems: [
                {
                    title: "riskSettings",
                    icon: "mdi-tune-vertical",
                    url: "/risk-management/risk-management-settings",
                    permissions: ["RiskManagementSettings"],
                    write: []
                },
                {
                    title: "riskPlan",
                    icon: "mdi-circle-edit-outline",
                    url: "/risk-management/plan",
                    permissions: ["RiskManagementPlan"],
                    write: []
                }
            ]
        },
        {
            title: "userBehaviourAnalysis",
            icon: "mdi-chart-line",
            group: "user-behaviour-analysis",
            permissions: ["UBAUsers"],
            write: [],
            subItems: [
                {
                    title: "userBehaviourAnalysisUsers",
                    icon: "mdi-account-multiple",
                    url: "/user-behaviour-analysis/uba-users",
                    permissions: ["UBAUsers"],
                    write: []
                },
                {
                    title: "userBehaviourAnalysisStatements",
                    icon: "mdi-security",
                    url: "/user-behaviour-analysis/statements",
                    permissions: ["Statement"],
                    write: []
                },
            ]
        },
        {
            title: "monitoringAndControl",
            icon: "mdi-binoculars",
            group: "monitoring-and-control",
            permissions: ["RequestLog", "DatabaseLog"],
            write: [],
            subItems: [
                {
                    title: "requestLog",
                    icon: "mdi-arrow-decision-outline",
                    url: "/monitoring-and-control/request-log",
                    permissions: ["RequestLog"],
                    write: []
                },
                {
                    title: "databaseLog",
                    icon: "mdi-database-settings",
                    url: "/monitoring-and-control/database-log",
                    permissions: ["DatabaseLog"],
                    write: []
                },
            ]
        },
        {
            title: "grammarManagement",
            icon: "mdi-code-braces",
            roles: [1],
            group: "grammars",
            permissions: ["Grammar", "Classes","GrammarDataCategories", "GrammarTokens"], // "GrammarDataTypes",
            write: [],
            subItems: [
                {
                    title: "categories",
                    icon: "mdi-code-greater-than-or-equal",
                    url: "/grammars/grammars_data_categories",
                    permissions: ["DataCategory"],
                    write: []
                },
                {
                    title: "classes",
                    icon: "mdi-view-dashboard-outline",
                    url: "/grammars/classes",
                    permissions: ["Classes"],
                    write: []
                },
                {
                    title: "grammars_tokens",
                    icon: "mdi-code-greater-than-or-equal",
                    url: "/grammars/grammars_tokens",
                    permissions: ["GrammarToken"],
                    write: []
                },
                {
                    title: "grammars",
                    icon: "mdi-code-braces",
                    url: "/grammars/grammars",
                    permissions: ["Grammar"],
                    write: []
                },
                // {
                //     title: "grammars_data_types",
                //     icon: "mdi-code-greater-than-or-equal",
                //     url: "/grammars/grammars_data_types",
                //     permissions: ["GrammarDataTypes"],
                //     write: []
                // },
 
            ],
        },
        {
            title: "usersPermissionsProcesses",
            icon: "mdi-account",
            group: "users",
            permissions: ["User", "Role", "Organization", "DataPermissions", "UserDatabaseConnection", "FailedLoginThresholds", "BlockedUsers"],
            write: [],
            subItems: [
                {
                    title: "rolesAndPermissions",
                    icon: "mdi-security",
                    url: "/users/roles-permissions",
                    permissions: ["Role"],
                    write: []
                },
                {
                    title: "dataPermissions",
                    icon: "mdi-cog-outline",
                    url: "/users/data-permissions",
                    permissions: ["DataPermissions"],
                    write: []
                },
                {
                    title: "organizations",
                    icon: "mdi-warehouse",
                    url: "/users/organizations",
                    permissions: ["Organization"],
                    write: []
                },
                {
                    title: "users",
                    icon: "mdi-account",
                    url: "/users/users",
                    permissions: ["User"],
                    write: []
                },
                {
                    title: "userDatabaseConnection",
                    icon: "mdi-connection",
                    url: "/users/user-database-connection",
                    permissions: ["UserDatabaseConnection"],
                    write: []
                },
                {
                    title: "failedLoginThresholds",
                    icon: "mdi-account-alert",
                    url: "/users/failed-login-thresholds",
                    permissions: ["FailedLoginThresholds"],
                    write: []
                },
                {
                    title: "blockedUsers",
                    icon: "mdi-account-off",
                    url: "/users/blocked-users",
                    permissions: ["BlockedUsers"],
                    write: []
                }
            ]
        },
        {
            title: "generalSettings",
            icon: "mdi-cog-outline",
            url: "/general-settings",
            permissions: ["Settings"],
            write: []
        },
        // {
        //     title: "parameters",
        //     icon: "mdi-application-variable-outline",
        //     url: "/settings/parameters",
        //     permissions: ["Parameters"],
        //     write: []
        // },
        {
            title: "privacyLevels",
            icon: "mdi-folder-star-outline",
            url: "/privacy-levels",
            permissions: ["PrivacyLevel"],
            write: []
        },
    ],
    userCurrentPermissions: [],
    userMenuList: [],
};


const getters = {
    getActualMenu(state) {
        return state.menuList;
    },
    getMenuList(state) {
        return state.userMenuList;
    },
    userCurrentPermissions(state) {
        return state.userCurrentPermissions;
    },
    getFlattedList(state) {
        const flattenMenuList = (arr) => {
            return arr.reduce((flatArr, item) => {
                flatArr.push(item);
                if (item.subItems) {
                    flatArr.push(...flattenMenuList(item.subItems));
                }
                return flatArr;
            }, []);
        };

        const flattenedMenuList = flattenMenuList(state.userMenuList);
        return flattenedMenuList;
    }
}

const actions = {
    getFilteredMenuItems({state, rootGetters, getters, commit}) {
        if (!store.state.user.is_superuser) {
            const flatList = flatMyList([...state.menuList]);
            // eğer alt menü varsa ve uzunluğu 0'dan farklı ise gösterilmiyor
            const new_list = flatList.filter(i => !(i.subItems && i.subItems.length === 0));
            commit('setUserMenu', new_list)
        } else {
            commit('setUserMenu', state.menuList)
        }
    }
};


const mutations = {
    setUserMenu(state, payload) {
        state.userMenuList = payload;
    },
    setActualMenu(state, payload) {
        state.menuList = payload;
    },
    setUserCurrentPermissions(state, payload) {
        state.userCurrentPermissions = payload;
    }
};


function flatMyList(menuList) {
    let all_perm = store.state.permissions
    let filteredMenuList = [];
    const userPermissions = store.state.permissions;
    menuList.forEach((menuItem) => {
        if (menuItem.permissions) {
            if (menuItem.subItems && menuItem.permissions.find(perm => userPermissions.includes('read' + perm))) {
                let filteredSubItems = [];
                filteredSubItems = flatMyList(menuItem.subItems)
                menuItem.subItems = filteredSubItems;
                filteredMenuList.push(menuItem);
            } else if (menuItem.permissions.every(perm => userPermissions.includes('read' + perm))) {
                all_perm.filter(i => {
                    if (i.startsWith('write')) {
                        menuItem.write.push(i);
                        return true
                    }
                    return false;
                })
                filteredMenuList.push(menuItem);
            }
        } else {
            if (!menuItem.subItems && !menuItem.permissions) {
                filteredMenuList.push(menuItem);
            }
            if (menuItem.subItems) {
                let filteredSubItems = [];
                filteredSubItems = flatMyList(menuItem.subItems)
                menuItem.subItems = filteredSubItems;
            }
        }
    })
    return filteredMenuList;

}


export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};







































import $axios from "@/plugins/axios";
import i18n from "@/plugins/i18n";
import { socket } from "@/plugins/webSocket";
import store from "@/store";
import isEmpty from "lodash/isEmpty";
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const DEFAULT_TITLE = "Resepsiyon";

const routes = [
  {
    path: "/login",
    name: "login",
    meta: {
      title: "pages.login",
    },
    component: () => import("@/views/auth/Login.vue"),
  },
  {
    path: "/register",
    name: "register",
    meta: {
      title: "pages.register",
    },
    component: () => import("@/views/auth/Register.vue"),
  },
  // {
  //   path: "/forgot-password",
  //   name: "ForgotPassword",
  //   meta: {
  //     title: "pages.forgotPassword",
  //   },
  //   component: () => import("@/views/auth/ForgotPassword.vue"),
  // },
  {
    path: "/set-password",
    name: "SetPassword",
    meta: {
      title: "pages.setPassword",
    },
    component: () => import("@/views/auth/SetPassword.vue"),
  },
  {
    path: "/dashboard",
    name: "dashboard",
    alias: "/",
    meta: {
      title: "pages.dashboard",
      layout: "AppLayoutMain",
    },
    component: () => import("@/views/Dashboard/Index.vue"),
  },
  {
    path: "/repositories/scan",
    name: "scan",
    meta: {
      title: "pages.scanRepository",
      layout: "AppLayoutMain",
      permission: "readScanRepository"
    },
    component: () => import("@/views/Repository/ScanRepositories.vue"),
  },
  {
    path: "/data-analysis/database-mapping",
    name: "databaseMapping",
    meta: {
      title: "pages.databaseMapping",
      layout: "AppLayoutMain",
      permission: "readDbMapping"
    },
    component: () => import("@/views/DataAnalysis/DatabaseMapping.vue"),
  },
  {
    path: "/users/users",
    name: "users",
    meta: {
      title: "pages.users",
      layout: "AppLayoutMain",
      permission: "readUser",
    },
    component: () => import("@/views/User/Index.vue"),
  },
  {
    path: "/users/user-database-connection",
    name: "users",
    meta: {
      title: "pages.userDatabaseConnection",
      layout: "AppLayoutMain",
      permission: "readUserDatabaseConnection",
    },
    component: () => import("@/views/User/UserDatabaseConnection.vue"),
  },
  {
    path: "/users/failed-login-thresholds",
    name: "users",
    meta: {
      title: "pages.failedLoginThresholds",
      layout: "AppLayoutMain",
      permission: "readFailedLoginThresholds",
    },
    component: () => import("@/views/User/FailedLoginThresholds.vue"),
  },
  {
    path: "/users/blocked-users",
    name: "users",
    meta: {
      title: "pages.blockedUsers",
      layout: "AppLayoutMain",
      permission: "readBlockedUsers",
    },
    component: () => import("@/views/User/BlockedUsers.vue"),
  },
  {
    path: "/repositories/repositories",
    name: "repositories",
    meta: {
      title: "pages.repositories",
      layout: "AppLayoutMain",
      permission: "readRepository",
    },
    component: () => import("@/views/Repository/Repositories.vue"),
  },
  {
    path: "/risk-management/risk-management-settings",
    name: "RiskManagementSettings",
    meta: {
      title: "pages.riskManagementSettings",
      roles: [2, 3, 4],
      layout: "AppLayoutMain",
      permission: "readRiskManagementSettings",
    },
    component: () => import("@/views/RiskManagement/Settings.vue"),
  },
  {
    path: "/risk-management/plan",
    name: "RiskManagementPlan",
    meta: {
      title: "pages.riskManagementPlan",
      roles: [2, 3, 4],
      layout: "AppLayoutMain",
      permission: "readRiskManagementPlan",
    },
    component: () => import("@/views/RiskManagement/Plan.vue"),
  },
  {
    path: "/users/roles-permissions",
    name: "RolesPermissions",
    meta: {
      title: "pages.rolesAndPermissions",
      roles: [2, 3, 4],
      layout: "AppLayoutMain",
      permission: "readRole",
    },
    component: () => import("@/views/User/RolesPermissions.vue"),
  },
  {
    path: "/general-settings",
    name: "GeneralSettings",
    meta: {
      title: "pages.generalSettings",
      roles: [2, 3, 4],
      layout: "AppLayoutMain",
      permission: "readSettings",
    },
    component: () => import("@/views/GeneralSettings.vue"),
  },
  {
    path: "/users/data-permissions",
    name: "DataPermissions",
    meta: {
      title: "pages.dataPermissions",
      roles: [2, 3, 4],
      layout: "AppLayoutMain",
      permission: "readDataPermissions",
    },
    component: () => import("@/views/User/DataPermissions.vue"),
  },
  {
    path: "/users/organizations",
    name: "Organizations",
    meta: {
      title: "pages.organizations",
      roles: [2, 3, 4],
      layout: "AppLayoutMain",
      permission: "readOrganization",
    },
    component: () => import("@/views/User/Organizations.vue"),
  },
  // {
  //   path: "/settings/parameters",
  //   name: "Parameters",
  //   meta: {
  //     title: "pages.parameters",
  //     roles: [2, 3, 4],
  //     layout: "AppLayoutMain",
  //     permission: "readParameters",
  //   },
  //   component: () => import("@/views/settings/Parameters.vue"),
  // },
  {
    path: "/grammars/classes",
    name: "Classes",
    meta: {
      title: "pages.classes",
      layout: "AppLayoutMain",
      permission: "readClasses"
    },
    component: () => import("@/views/Grammars/Classes.vue"),
  },
  {
    path: "/grammars/grammars",
    name: "Grammars",
    meta: {
      title: "pages.grammars",
      roles: [2, 3, 4],
      layout: "AppLayoutMain",
      permission: "readGrammars"
    },
    component: () => import("@/views/Grammars/Grammars.vue"),
  },
  // {
  //   path: "/grammars/grammars_data_types",
  //   name: "GrammarsDataTypes",
  //   meta: {
  //     title: "pages.grammars_data_types",
  //     roles: [2, 3, 4],
  //     layout: "AppLayoutMain",
  //     permission: "readGrammarDataTypes"
  //   },
  //   component: () => import("@/views/Grammars/GrammarsDataTypes.vue"),
  // },
  {
    path: "/grammars/grammars_data_categories",
    name: "GrammarsDataCategories",
    meta: {
      title: "pages.grammars_data_categories",
      roles: [2, 3, 4],
      layout: "AppLayoutMain",
      permission: "readGrammarDataCategories"
    },
    component: () => import("@/views/Grammars/GrammarsDataCategories.vue"),
  },
  {
    path: "/grammars/grammars_tokens",
    name: "GrammarsTokens",
    meta: {
      title: "pages.grammars_tokens",
      roles: [2, 3, 4],
      layout: "AppLayoutMain",
      permission: "readGrammarTokens"
    },
    component: () => import("@/views/Grammars/GrammarsTokens.vue"),
  },
  {
    path: "/privacy-levels",
    name: "PrivacyLevels",
    meta: {
      title: "pages.privacyLevels",
      roles: [2, 3, 4],
      layout: "AppLayoutMain",
      permission: "readPrivacyLevel"
    },
    component: () => import("@/views/PrivacyLevels.vue"),
  },
  {
    path: "/data-analysis/discovery",
    name: "Discovery",
    meta: {
      title: "pages.discovery",
      roles: [2, 3, 4],
      layout: "AppLayoutMain",
      permission: "readDiscovery",
    },
    component: () => import("@/views/DataAnalysis/Discovery.vue"),
  },
  // {
  //   path: "/reports",
  //   name: "Reports",
  //   meta: {
  //     title: "pages.reports",
  //     roles: [2, 3, 4],
  //     layout: "AppLayoutMain",
  //     permission: "readReports"
  //   },
  //   component: () => import("@/views/Reports/Index.vue"),
  // },

  {
    path: "/data-analysis/classification",
    name: "classification",
    meta: {
      title: "pages.classification",
      roles: [2, 3, 4],
      layout: "AppLayoutMain",
      permission: "readClassificationCategories"
    },
    component: () => import("@/views/DataAnalysis/Classification.vue"),
  },
  {
    path: "/data-analysis/clustering",
    name: "clustering",
    meta: {
      title: "pages.clustering",
      roles: [2, 3, 4],
      layout: "AppLayoutMain",
      permission: "readClustering"
    },
    component: () => import("@/views/DataAnalysis/Clustering.vue"),
  },
  {
    path: "/user-behaviour-analysis/uba-users",
    name: "user-behaviour-users",
    meta: {
      title: "pages.user-behaviour-users",
      roles: [2, 3, 4],
      layout: "AppLayoutMain",
      permission: "readUBAUsers"
    },
    component: () => import("@/views/UserBehaviourAnalysis/Users.vue"),
  },
  {
    path: "/user-behaviour-analysis/statements",
    name: "user-behaviour-statements",
    meta: {
      title: "pages.user-behaviour-statements",
      roles: [2, 3, 4],
      layout: "AppLayoutMain",
      permission: "readStatement"
    },
    component: () => import("@/views/UserBehaviourAnalysis/Statements.vue"),
  },
  {
    path: "/user-behaviour-analysis/uba-users/queries/:id",
    name: "user-behaviour-query-details",
    meta: {
      title: "pages.user-behaviour-query-details",
      roles: [2, 3, 4],
      layout: "AppLayoutMain",
      permission: "readUBAQueryDetails"
    },
    component: () => import("@/views/UserBehaviourAnalysis/QueryDetails.vue"),
  },
  {
    path: "/user-behaviour-analysis/user-queries/:id",
    name: "user-behaviour-queries",
    meta: {
      title: "pages.user-behaviour-queries",
      roles: [2, 3, 4],
      layout: "AppLayoutMain",
      permission: "readUBAQueries"
    },
    component: () => import("@/views/UserBehaviourAnalysis/UserQueries.vue"),
  },
  {
    path: "/physical-logical-deleting-anonymizing/database-anonymizing",
    name: "databaseAnonymizing",
    meta: {
      title: "pages.databaseAnonymizing",
      layout: "AppLayoutMain",
      permission: "readDbAnonymizing"
    },
    component: () => import("@/views/PhysicalLogicalDeletingAnonymizing/DbAnonymizing.vue"),
  },
  {
    path: "/physical-logical-deleting-anonymizing/physical-logical-deleting",
    name: "physicalLogicalDeleting",
    meta: {
      title: "pages.physicalLogicalDeleting",
      layout: "AppLayoutMain",
      permission: "readPhysicalLogicalDeleting"
    },
    component: () => import("@/views/PhysicalLogicalDeletingAnonymizing/PhysicalLogicalDeleting.vue"),
  },
  {
    path: "/monitoring-and-control/request-log",
    name: "requestLog",
    meta: {
      title: "pages.requestLog",
      layout: "AppLayoutMain",
      permission: "readRequestLog"
    },
    component: () => import("@/views/MonitoringAndControl/RequestLog.vue"),
  },
  {
    path: "/monitoring-and-control/database-log",
    name: "databaseLog",
    meta: {
      title: "pages.databaseLog",
      layout: "AppLayoutMain",
      permission: "readDatabaseLog"
    },
    component: () => import("@/views/MonitoringAndControl/DatabaseLog.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {

  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = [
    "/login",
    "/register",
    "/forgot-password",
    "/set-password",
  ];

  if (to.meta.title) {
    document.title = DEFAULT_TITLE  + " - " + i18n.t(to.meta.title);
  } else {
    document.title = DEFAULT_TITLE;
  }
  
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem("access");
  const isAuthenticated = store.state.auth.isAuthenticated;
  store.commit("setPageLoading", true);
  if (authRequired) {
    if (!loggedIn && to.path !== "/login" && from.path !== "/login") {
        return next("/login");
    }

    if (!isAuthenticated) {
      $axios
        .post("currentUser/")
        .then(({ data }) => {
          store.commit("auth/setAuth", data);
          socket.reconnect();
          store.commit("setPageLoading", false);
          return next();
        })
        .catch(() => {
          store.commit("auth/purgeAuth");
        });
    }
    if (socket.readyState == socket.CLOSED) {
      console.log('if')
      socket.reconnect();
    } else if (socket.readyState == socket.CLOSING) {
      console.log('else if')
      socket.close();
      socket.reconnect();
    }
    // console.log(socket.CONNECTING, socket.OPEN, socket.CLOSED, socket.CLOSING, socket.readyState)

    let isAllowed = store.state.auth.user.is_superuser ? true : false
    if (to.meta.permission && to.meta.permission.length > 0 && !isAllowed) {
      isAllowed = (store.state.auth.permissions).find(p => to.meta.permission.includes(p))
      store.commit("setPageLoading", false);
      if (! isAllowed) return next(from.path)
    }
    store.commit("setPageLoading", false);
    next();
  } else {
    store.commit("setPageLoading", false);
    next()
  };
});


router.afterEach((to) => {
  Vue.nextTick(() => {
    
  });
});

export default router;

<template>
  <v-container class="white h-100pc" fluid fill-height>
    <div class="text-center empty-container">
      <v-img
        class="mt-9 mx-auto"
        :max-height="height"
        :max-width="width"
        :src="require(`@/assets/modules/` + module_name + `/empty.png`)"
      ></v-img>
      <h3 class="headline text-center grey--text mt-2">{{ text }}</h3>
      <v-btn outlined v-if="info" @click='$router.push(info_path)' class="headline text-center blue--text text--darken-3">{{ info }}</v-btn>
    </div>
  </v-container>
</template>
<script>
export default {
  data: () => ({}),
  created() {},
  methods: {},
  props: {
    module_name: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    info: {
      type: String,
      required: false,
    },
    width: {
      type: [String, Number],
      default: () => {
        return '350';
      },
    },
    height: {
      type: [String, Number],
      default: () => {
        return '350';
      },
    },
    info_path: {
      type: String,
      required: false,
      default: '/'
    }
  },
};
</script>

<style scoped>
.empty-container {
  width: 400;
  height: 350px;
  line-height: 250px;
  margin: 0 auto;
  border-radius: 50%;
}
.h-100pc {
  height: 100%;
}
</style>
import App from "@/App.vue";
import "@/assets/css/custom.css";
import { helpers } from "@/mixins/helpers";
import { toast } from "@/mixins/toastMixin";
import $axios from "@/plugins/axios";
import i18n from "@/plugins/i18n";
import "@/plugins/vee-validate";
import vuetify from "@/plugins/vuetify";
import { socket } from "@/plugins/webSocket";
import router from "@/router";
import store from "@/store";
import { DateTime } from "luxon";
import Vue from "vue";
import VueJsonToCsv from "vue-json-to-csv";
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css';

import Empty from "./components/Empty";
Vue.component('empty-page', Empty)

const options = {
  confirmButtonColor: '#41b882',
  cancelButtonColor: '#ff7674'
};

Vue.prototype.axios = $axios;
Vue.prototype.$luxon = DateTime;
Vue.prototype.$toast = toast;
Vue.use(VueJsonToCsv);
Vue.use(VueSweetalert2, options);
Vue.prototype.$helpers = helpers;
Vue.prototype.$socket = socket;


Vue.directive('can', {
  bind: function (el, binding, vnode) {
    const user_perms = store.state.auth.permissions
    if (!store.getters['auth/currentUser'].is_superuser && !(user_perms.includes(binding.value))) {
      vnode.elm.style.display = "none";
    }
  }
})


new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

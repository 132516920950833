const state = {
  grammarParams: {
    encodingTypes: [
      { id: 1, name: "UTF-8" },
      { id: 2, name: "UTF-9" },
    ],
    types: [
      { id: 1, name: "grammarParam.type.pattern" },
      { id: 2, name: "grammarParam.type.tokens" },
      { id: 3, name: "grammarParam.type.all" },
    ],
  },
};

const getters = {
  getGrammarParams() {
    return state.grammarParams;
  },
};

const actions = {};

const mutations = {};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};

<template>
  <AppLayout>
    <router-view />
  </AppLayout>
</template>

<script>
import AppLayout from "@/layouts/AppLayout.vue";
import { localeChanged, localize } from "vee-validate";
import { permissionMixin } from "@/mixins/permissionMixin";

export default {
  name: "App",
  components: { AppLayout },
  mounted() {
    // get the language from the local storage
    let lang = localStorage.getItem("resepsiyon-language");
    if (!lang) {
      localStorage.setItem("resepsiyon-language", "tr");
      lang = localStorage.getItem("resepsiyon-language");
    }
    this.$root.$i18n.locale = lang;
    this.$vuetify.lang.current = lang;
    localize(lang);
    localeChanged();
    this.axios.post("change_user_language/", { language: lang })
  },
  mixins: [permissionMixin]
};
</script>
